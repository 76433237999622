import { OwnershipType } from './ownershipType';

export type Pharmacy = {
  [key: string]: any;
  id: string;
  branchNumber: string;
  code: string;
  businessName: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  postcode: string;
  telephoneNumber: string;
  servicesOffered: string[];
  servicesOfferedByIds?: string[];
  offersByIds?: string[];
  pharmacyOpeningTimes: OpeningTimes;
  adHocOpeningTimes: AdHocOpeningTimes[];
  live: boolean;
  liveOnDate?: string | null;
  visibleOnMap: boolean;
  email: string;
  keyContacts: string[];
  ownershipType: OwnershipType;
  odsCode: string;
  allowManualReordering: boolean;
  groupId: string | null;
  deliveryOnly: boolean;
  isAutoCollectionEnabled: boolean | null;
  autoCollectionCheckinDuration: number | null;
  isAutoReadyForCollectionEnabled: boolean | null;
  readyForCollectionCheckingDuration: number | null;
  pMRConfig: PMRConfig | null;
  isServiceBookingEnabled: boolean;
};

export const defaultPharmacy: Pharmacy = {
  id: '',
  branchNumber: '',
  code: '',
  businessName: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  postcode: '',
  telephoneNumber: '',
  servicesOffered: [],
  servicesOfferedByIds: [],
  offersByIds: [],
  pharmacyOpeningTimes: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },
  adHocOpeningTimes: [],
  live: false,
  visibleOnMap: true,
  email: '',
  keyContacts: [],
  ownershipType: OwnershipType.Owned,
  odsCode: '',
  allowManualReordering: false,
  groupId: null,
  deliveryOnly: false,
  isAutoCollectionEnabled: false,
  autoCollectionCheckinDuration: 0,
  isAutoReadyForCollectionEnabled: false,
  readyForCollectionCheckingDuration: 0,
  pMRConfig: null,
  isServiceBookingEnabled: false
};

type OpeningTimes = {
  monday: OpeningInterval[];
  tuesday: OpeningInterval[];
  wednesday: OpeningInterval[];
  thursday: OpeningInterval[];
  friday: OpeningInterval[];
  saturday: OpeningInterval[];
  sunday: OpeningInterval[];
};

export type AdHocOpeningTimes = {
  adHocDt: string;
  closed: boolean;
  times: OpeningInterval[];
};

type OpeningInterval = {
  from: string;
  to: string;
};

type PMRConfig = {
  enabled: boolean,
  provider: string
};

export enum PMRTypes {
  Cegedim = 'Cegedim',
  Titan = 'Titan'
}
