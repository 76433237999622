import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DetailTab from './DetailTab';
import { Offer, defaultOffer } from '../../types/offer';
import { Alert, Button, Col, Form, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Role } from '../../types/role';
import { hasRole } from '../../utils/auth';
import axios from 'axios';
import Config from '../../config';

export default ({ fetchOffers }: fetchOffersType) => {
  const { offerId } = useParams<{ offerId: string }>();
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [offer, setOffer] = useState<Offer | null>(null);
  const [initialOffer, setInitialOffer] = useState<Offer | null>(null);
  const [validated, setValidated] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const fetchOffer = async () => {
    try {
      const url = Config.getConfigVar('pharmacyOffersEndpoint');
      const result = await axios.get(`${url}/${offerId}`);

      setOffer(result.data);
      setInitialOffer(result.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Failed to load offer');
    }
  };

  useEffect(() => {
    if (offerId === 'new') {
      setInitialOffer(defaultOffer);
      setOffer(defaultOffer);
      setLoading(false);
    } else {
      fetchOffer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  const updateOffer = async () => {
    setModalLoading(true);
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const url = Config.getConfigVar('pharmacyOffersEndpoint');

      const formData = new FormData();
      for (var key in offer) {
        let value = offer[key];
        if (value === null) {
          value = '';
        }
        formData.append(key, value);
      }

      if (offer?.id) formData.append('offerId', offer.id);
      const result = await axios.put(
        url,
        formData,
        config
      );
      fetchOffers();
      setShowSaveModal(false);
      setModalLoading(false);
      if (result.data?.id !== offerId) {
        history.push(`/offers/${result.data?.id}`);
      } else {
        setLoading(true);
        fetchOffer();
      }
    } catch (e) {
      setModalLoading(false);
      setShowSaveModal(false);
      setError('Failed to save title');
    }
  }

  if (!offer) {
    return <Alert variant="danger">Error loading offer</Alert>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const handleSubmit = (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      //validate
      setShowSaveModal(false);
    } else {
      setShowSaveModal(true);
    }

    setValidated(true);
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {/* Block enter key submission with a disabled submit */}
      <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true"></button>
      <Row className="my-3">
        <Col sm={6}>
          <h2>{offerId === 'new' ? 'New Offer' : initialOffer?.title}</h2>
        </Col>
        {hasRole(Role.Admin_Offers_Edit) && (
          <Col sm={6}>
            <p className="text-right">
              <Button
                variant="secondary"
                disabled={initialOffer === offer}
                onClick={() => {
                  setOffer(initialOffer);
                  setValidated(false);
                }}
              >
                <FontAwesomeIcon icon="history" /> Reset
              </Button>{' '}
              <Button variant="primary" disabled={initialOffer === offer} type="submit">
                <FontAwesomeIcon icon="save" /> Save
              </Button>
            </p>
          </Col>
        )}
      </Row>
      <Tabs defaultActiveKey="details" id="offer-tabs" className="my-3">
        <Tab eventKey="details" title="Details" className="my-3 mx-2">
          <DetailTab offer={offer} setOffer={setOffer} />
        </Tab>
      </Tabs>
      <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Save Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to save changes?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSaveModal(false)}>
            Cancel
          </Button>
          {modalLoading ? (
            <Button variant="primary" disabled>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="sr-only">Saving...</span>
            </Button>
          ) : (
            <Button variant="primary" onClick={updateOffer}>
              Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export type OfferTabType = {
  offer: Offer;
  setOffer: (offer: Offer) => void;
};

type fetchOffersType = {
  fetchOffers: () => void;
};