import React, { useEffect, useRef, useState } from 'react';
import { OfferTabType } from './Offer';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ offer, setOffer }: OfferTabType) => {
  const textareaRef = useRef<any>();
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [uploadFileName, setUploadFileName] = useState<string>('');
  const [resetFileInput, setResetFileInput] = useState(true);

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + "px";
  }, [offer.description]);

  useEffect(() => {
    if (uploadFile) setUploadFileName(uploadFile.name);
    setOffer({
      ...offer,
      image: uploadFile,
    });
  }, [uploadFile]);

  useEffect(() => {
    if (!resetFileInput) setResetFileInput(true);
  }, [resetFileInput]);

  useEffect(() => {
    if (offer.filename) {
      setUploadFileName(offer.filename);
    }
  }, [offer])

  return (
    <>
      <h4 className="mb-4">Details</h4>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Title
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required
            placeholder="Title"
            value={offer.title}
            onChange={(e: any) => {
              setOffer({
                ...offer,
                title: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Offer Code
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            required={false}
            placeholder="Offer Code"
            value={offer.offerCode}
            onChange={(e: any) => {
              setOffer({
                ...offer,
                offerCode: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Live Date
        </Form.Label>
        <Col sm={7}>
          <DatePicker
            required
            dateFormat="dd/MM/yyyy"
            selected={new Date(offer.liveOnDate)}
            onChange={(date) => {
              if (date) {
                setOffer({
                  ...offer,
                  liveOnDate: date.toISOString()
                });
              }
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Expiry Date
        </Form.Label>
        <Col sm={7}>
          <DatePicker
            required
            dateFormat="dd/MM/yyyy"
            selected={new Date(offer.expiry)}
            onChange={(date) => {
              if (date) {
                setOffer({
                  ...offer,
                  expiry: date.toISOString()
                });
              }
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Description
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="textarea"
            ref={textareaRef}
            required
            placeholder="Description"
            value={offer.description}
            onChange={(e: any) => {
              setOffer({
                ...offer,
                description: e.target.value,
              });
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Image
        </Form.Label>
        {!uploadFileName ?
          <Col sm={7}>
            <Button variant="primary">
              <FontAwesomeIcon icon="plus-circle" /> <label htmlFor="imgupload" style={{ margin: '0px' }}>Add Image</label>
            </Button>
            {resetFileInput &&
              (<input
                className="ml-3 d-none"
                type="file"
                id="imgupload"
                onChange={(e: any) => setUploadFile(e.target.files[0])}
                accept=".png, .jpg, .jpeg"
              />
              )
            }
          </Col>
          : <>
            <Col sm={5}>
              <p className="float-left">{uploadFileName}</p>
              <br />
              {offer.filepath && <img width="800px" src={offer.filepath}></img>}
            </Col>

            <Col sm={2}>
              <div className="float-right">
                <Button
                  variant="danger"
                  className='mr-3'
                  onClick={() => {
                    setUploadFileName('');
                    setUploadFile(null);
                    setResetFileInput(false);
                    setOffer({
                      ...offer,
                      filename: null,
                      filepath: null
                    });
                  }}
                >
                  Remove Image
                </Button>
              </div>
            </Col>
          </>
        }
      </Form.Group>
    </>
  );
}

