import React, { useState, useEffect } from 'react';
import { PharmacyTabType } from './Pharmacy';
import { Service } from '../../types/service';
import axios from 'axios';
import Config from '../../config';
import { hasPharmacyEdit } from '../../utils/auth';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';

export default ({ pharmacy, setPharmacy }: PharmacyTabType) => {
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);
  const [requestNewService, setRequestNewService] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [serviceRequests, setServiceRequests] = useState<any[]>([]);
  const [enabledForBooking, setEnabledForBooking] = useState(false);

  const servicesByIds = pharmacy.servicesOfferedByIds || [];

  const availableServices = services.map((s) => ({
    value: s.id,
    label: s.name,
  }));

  const servicesProvided = availableServices.filter((s) => servicesByIds.includes(s.value));

  const fetchServices = async () => {
    try {
      setLoading(true);
      setError(false);
      const [servicesRes, requestsRes] = await Promise.all([
        axios.get(`${Config.getConfigVar('pharmacyServicesEndpoint')}/${pharmacy.id}`),
        axios.get(`${Config.getConfigVar('serviceRequestsEndpoint')}/${pharmacy.id}`),
      ]);
      setServices(servicesRes.data);
      setServiceRequests(requestsRes.data);
      setEnabledForBooking(pharmacy.isServiceBookingEnabled);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const enableDisablePharmacy = async () => {
    try {
      setLoading(true);
      setError(false);
      if (!enabledForBooking) {
        await axios.post(
          `${Config.getConfigVar('serviceBookingCreateOwnerEndpoint')}/${pharmacy.id}`
        );
      } else {
        let url = Config.getConfigVar('serviceBookingUpdateOwnerEndpoint');
        await axios.post(url, {
          PharmacyId: pharmacy.id,
          active: !enabledForBooking,
          groupId: pharmacy.groupId,
        });
      }
      setPharmacy({
        ...pharmacy,
        isServiceBookingEnabled: true,
      });
      setEnabledForBooking(!enabledForBooking);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h4 className="mb-4">Services Offered</h4>
      <Row className="mb-4">
        <Col sm={8}>
          <Select
            isMulti
            isDisabled={!hasPharmacyEdit()}
            options={availableServices}
            value={servicesProvided}
            onChange={(selected: any) => {
              if (!selected) {
                selected = [];
              }
              setPharmacy({
                ...pharmacy,
                servicesOffered: Array.isArray(selected)
                  ? selected.map((s) => s.label)
                  : [selected.label],
                servicesOfferedByIds: Array.isArray(selected)
                  ? selected.map((s) => s.value)
                  : [selected.value],
              });
            }}
          />
        </Col>
      </Row>
      {pharmacy.ownershipType === 'Owned' && (
        <>
          <h4 className="mb-4 mt-4">Request a New Service</h4>
          <Row>
            <Col sm={8}>
              <p>
                If a new service your pharmacy wants to offer is not available in the list, please
                submit a request for this. Once approved, it will be available for all pharmacies to
                select.
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={6}>
              <Form.Control
                type="text"
                placeholder="New service"
                value={requestNewService}
                onChange={(e: any) => setRequestNewService(e.target.value)}
              />
            </Col>
            <Col sm={2}>
              <Button onClick={() => setShowModal(true)} disabled={requestNewService.trim() === ''}>
                <FontAwesomeIcon icon="plus-circle" /> Request
              </Button>
            </Col>
          </Row>
          {serviceRequests.length > 0 && (
            <>
              <h4 className="mb-4 mt-5">Requested Services</h4>
              <Row>
                <Col sm={10}>
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th>Service Name</th>
                        <th>Requested By</th>
                        <th>Requested On</th>
                        <th>Reviewed By</th>
                        <th>Reviewed On</th>
                        <th className="text-center">Approved</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceRequests.map((req, i) => (
                        <tr key={i}>
                          <td>{req.requestedServiceName}</td>
                          <td>{req.requestedByEmail}</td>
                          <td>
                            {format(new Date(req.requestDt), Config.getConfigVar('dateFormat'))}
                          </td>
                          <td>{req.reviewedByEmail}</td>
                          <td>
                            {req.reviewedDt &&
                              format(new Date(req.reviewedDt), Config.getConfigVar('dateFormat'))}
                          </td>
                          <td className="text-center">
                            {req.approved ? (
                              <FontAwesomeIcon icon="check-circle" className="text-primary" />
                            ) : (
                              <FontAwesomeIcon icon="times-circle" className="text-danger" />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      <h4 className="mb-4 mt-4">Service booking</h4>
      <Row>
        <Col sm={8}></Col>
      </Row>
      {pharmacy.groupId && (
        <>
          {enabledForBooking ? (
            <p>This pharmacy is enabled for service booking</p>
          ) : (
            <>
              <p>
                Clicking this button will enable customers who have nominated this pharmacy to book
                in for services via the app or website.
              </p>
            </>
          )}
          <Button disabled={loading} onClick={enableDisablePharmacy}>
            <FontAwesomeIcon icon="check-circle" />{' '}
            {loading ? 'Loading...' : enabledForBooking ? 'Disable' : 'Enable'}
          </Button>
        </>
      )}
      {!pharmacy.groupId && (
        <>
          <p>Pharmacy needs to be added to a group to enable service booking</p>
        </>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to request the new service <strong>{requestNewService}</strong>?
          </p>
          <p>An admin will review the request once submitted.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          {sendingRequest ? (
            <Button variant="primary" disabled>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="sr-only">Sending...</span>
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={async () => {
                try {
                  setSendingRequest(true);
                  await axios.post(Config.getConfigVar('serviceRequestEndpoint'), {
                    pharmacyId: pharmacy.id,
                    serviceName: requestNewService,
                  });
                  setSendingRequest(false);
                  setShowModal(false);
                  setRequestNewService('');
                } catch {
                  setError(true);
                }
              }}
            >
              Send Request
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
